<template>
  <v-form
    v-model="valid"
    ref="form"
    class="user-username-form pa-3 pt-8"
    @submit.prevent.stop="updateUsername"
  >
    <v-row>
      <!-- mail attuale -->
      <v-col cols="12" sm="6">
        <v-text-field
          class="mt-2"
          :label="$t('profile.changeUsername.actualUsername')"
          v-model="userData.login"
          required
          outlined
          :rules="usernameRules"
          flat
          filled
          dense
          disabled
        />
      </v-col>

      <v-col cols="12" sm="6" class="mb-4 ma-sm-0">
        <v-text-field
          :label="$t('profile.changeUsername.newUsername')"
          class="mt-2"
          v-model="newLogin"
          required
          outlined
          flat
          filled
          dense
        />
      </v-col>
    </v-row>
    <span class="d-block mt-2" v-html="$t('profile.mandatoryField')"></span>

    <ResponseMessage class="mt-3" :response="responseMessage" />

    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        large
        depressed
        min-width="250"
        v-html="$t('common.confirm')"
        type="submit"
      />
    </div>
  </v-form>
</template>
<style lang="scss">
.user-username-form {
}
</style>
<script>
import ResponseMessage from "@/components/ResponseMessage.vue";

import UserService from "@/commons/service/userService";

import { requiredValue } from "~/validator/validationRules";

export default {
  name: "UserUsername",
  components: { ResponseMessage },
  props: {
    userData: { type: Object, required: false }
  },
  data() {
    return {
      valid: false,
      responseMessage: {},
      newLogin: "",
      usernameRules: [requiredValue()]
    };
  },
  methods: {
    async updateUsername() {
      try {
        if (this.$refs.form.validate()) {
          let res = await UserService.updateUsername(this.newLogin);
          if (res.response.status == 0) {
            this.responseMessage = {};
            this.$emit("refreshUser");
          } else {
            this.responseMessage = res.response;
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
