<template>
  <v-form
    v-model="valid"
    ref="form"
    class="user-password-form pa-3 pt-8"
    @submit.prevent.stop="updatePassword"
  >
    <v-row class="pt-2">
      <!-- vecchia password -->
      <v-col cols="12" sm="6">
        <v-text-field
          class="mt-2"
          :label="$t('profile.oldPassword')"
          v-model="userData.oldPassword"
          :append-icon="showPassword ? '$eye' : '$eyeOff'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="toggleShowPassword"
          required
          outlined
          flat
          filled
          dense
          @change="validate"
        />
      </v-col>
      <v-col cols="6" />
      <v-col cols="12" sm="6" class="mb-4 ma-sm-0">
        <v-text-field
          :label="$t('profile.newPassword')"
          class="mt-2"
          v-model="userData.password"
          :append-icon="showPassword ? '$eye' : '$eyeOff'"
          :type="showPassword ? 'text' : 'password'"
          :rules="passwordRules"
          @click:append="toggleShowPassword"
          required
          outlined
          flat
          filled
          dense
          @change="validate"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <!-- conferma password (visibile solo in registrazione) -->
        <v-text-field
          class="mt-sm-2"
          :label="$t('profile.passwordConfirm')"
          v-model="passwordConfirm"
          :append-icon="showPassword ? '$eye' : '$eyeOff'"
          :type="showPassword ? 'text' : 'password'"
          :rules="passwordConfirmRules"
          @click:append="toggleShowPassword"
          required
          outlined
          flat
          filled
          dense
          @change="validate"
        />
      </v-col>
    </v-row>
    <span class="d-block mt-5" v-html="$t('profile.mandatoryField')"></span>

    <ResponseMessage class="mt-3" :response="responseMessage" />

    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        large
        depressed
        min-width="250"
        v-html="$t('common.confirm')"
        type="submit"
      />
    </div>
    <recaptcha-disclaimer />
  </v-form>
</template>
<script>
import ResponseMessage from "@/components/ResponseMessage.vue";

import UserService from "@/commons/service/userService";

import {
  requiredValue,
  isPasswordMinimumLength
} from "~/validator/validationRules";
import RecaptchaDisclaimer from "~/components/RecaptchaDisclaimer.vue";

export default {
  name: "UserPassword",
  components: { ResponseMessage, RecaptchaDisclaimer },
  props: {
    userData: { type: Object, required: false }
  },
  data() {
    return {
      valid: false,
      responseMessage: {},
      passwordConfirm: null,
      showPassword: false,
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.userData.password || "Le password non coincidono"
      ]
    };
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    back() {
      this.$emit("back");
    },
    validate() {
      let _this = this;
      if (_this.$refs.form) {
        _this.valid = _this.$refs.form.validate();
      }
    },
    async updatePassword() {
      try {
        this.responseMessage = {};
        if (this.$refs.form.validate()) {
          let res = await UserService.updatePassword(
            this.userData.oldPassword,
            this.userData.password
          );
          if (res.response.status == 0) {
            this.responseMessage = {};
          } else {
            this.responseMessage = res.response;
          }
        } else {
          this.responseMessage = {
            errors: [
              {
                error: "Compilare correttamente la password prima di procedere"
              }
            ]
          };
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
