<template>
  <div class="profile-update-container scroll-container" v-if="userData">
    <CategoryTitle :category="category" />

    <h2 class="form-title" v-html="$t('profile.userInfoTitle')" />
    <UserData
      :key="key"
      v-if="userData"
      :userData="userData"
      :customClass="'grey lighten-2 rounded mb-8'"
      :navToRefName="navToRefName"
      @refreshUser="refreshUser"
    />

    <!--

      <h2
      class="form-title"
      v-html="$t('profile.contactPreferPersonInfo.title')"
      />
      
      <ContactPrefer
      :key="key"
      class="grey lighten-2 rounded mb-8"
      :userData="userData"
      :showContactPrefer="true"
      @refreshUser="refreshUser"
      />
    -->

    <h2 class="form-title" v-html="$t('profile.header.email')" />
    <UserEmail
      :key="key"
      class="grey lighten-2 rounded mb-8"
      :userData="userData"
    />

    <h2 class="form-title" v-html="$t('profile.header.username')" />
    <UserUsername
      :key="key"
      class="grey lighten-2 rounded mb-8"
      :userData="userData"
      @refreshUser="refreshUser"
    />

    <h2 class="form-title" v-html="$t('profile.header.password')" />
    <UserPassword class="grey lighten-2 rounded mb-8" :userData="userData" />

    <h2 class="form-title" v-html="$t('profile.header.inovice')" />
    <PaymentInvoice
      :key="key"
      class="grey lighten-2 rounded pa-3 pb-6"
      @refreshUser="refreshUser"
    />

    <div class="anonymize-block mt-10">
      <h2 class="form-title" v-html="$t('profile.header.anonymizeAccount')" />
      <span
        class="anonymize-profile-text d-block pa-5 rounded grey lighten-2"
        v-html="$t('profile.anonymizeProfile.text')"
      >
      </span>
      <ResponseMessage class="mt-3" :response="responseAnonymazeAccount" />
      <div class="d-flex justify-center justify-sm-end mt-3 mt-sm-6">
        <v-btn
          color="primary"
          depressed
          large
          min-width="250"
          @click="anonymizeAccount()"
          :loading="loadingAnonymizeAccount"
          >{{ $t("profile.anonymizeAccountBtn") }}
        </v-btn>
      </div>
    </div>

    <v-row no-gutters class="my-5">
      <v-col cols="12">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </v-col>
    </v-row>

    <v-dialog v-model="removeDialog" max-width="400">
      <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div>
      <v-card>
        <v-card-title class="anonymize-dialog-title text-h2">
          {{ $t("profile.anonymizeProfileDialog.title") }}
        </v-card-title>
        <v-card-text
          class="anonymize-dialog-text text-body-1"
          style="font-size: 1rem !important"
          v-html="$t('profile.anonymizeProfileDialog.text')"
        >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            @click.prevent="anonymizeAccount(true)"
            @mousedown.stop
          >
            {{ $t("common.yes") }}
          </v-btn>
          <v-btn class="primary" depressed text @click="removeDialog = false">
            {{ $t("common.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.profile-update-container {
  div[class*="col-"] {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      padding: 0 12px !important;
    }
  }
}
</style>
<script>
import { requiredValue, isEmail, isNumber } from "~/validator/validationRules";

import CategoryTitle from "@/components/category/CategoryTitle.vue";
import UserData from "@/components/profile/form/UserData.vue";
//import ContactPrefer from "@/components/profile/form/ContactPrefer.vue";

import UserPassword from "@/components/profile/form/UserPassword.vue";
import UserEmail from "@/components/profile/form/UserEmail.vue";
import PaymentInvoice from "@/components/payment/PaymentInvoice.vue";
import UserUsername from "@/components/profile/form/UserUsername.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";

import categoryMixins from "~/mixins/category";

// import { setPersonInfo } from "~/service/ebsn";
import UserService from "~/service/userService";

import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import get from "lodash/get";

export default {
  name: "ProfileUpdate",
  components: {
    CategoryTitle,
    UserData,
    UserPassword,
    UserEmail,
    PaymentInvoice,
    UserUsername,
    ResponseMessage
    //,ContactPrefer
  },
  mixins: [categoryMixins],
  directives: { mask },
  data() {
    return {
      key: 0,
      navToRefName: null,
      valid: true,
      birthdateMenu: false,
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      dateRules: [requiredValue("Digitare la data di nascita")],
      isEmail: isEmail,
      isNumber: isNumber,
      fidelityCardRules: [
        requiredValue(),
        isNumber("Deve contenere solo cifre")
      ],
      userData: null,
      supportData: {
        privacy1: true,
        privacy2: true,
        country: {
          itemId: null
        }
      },
      provinces: [],
      response: {},
      loadingAnonymizeAccount: false,
      removeDialog: false,
      responseAnonymazeAccount: {}
    };
  },
  computed: {
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    }
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout"
    }),
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    refreshUser() {
      this.getUserData();
    },
    async getUserData() {
      const result = await UserService.getUserDetail();

      if (result) {
        this.userData = result;
        this.key++;
      }
    },
    back() {
      this.$router.back();
    },
    async anonymizeAccount(perfom = false) {
      this.removeDialog = true;
      if (perfom) {
        this.loadingAnonymizeAccount = true;
        let result = await UserService.anonymizeAccountTokenRequest();
        if (result.response && result.response.status === 0) {
          this.removeDialog = false;
          // se utente davvero anonimizzato -> logout
          await this.doLogout();
          this.loadingAnonymizeAccount = false;
          this.$router.push({ name: "Home" });
        } else {
          this.responseAnonymazeAccount = result.response;
          this.removeDialog = false;
          this.loadingAnonymizeAccount = false;
        }
      }
    }
  },
  async mounted() {
    this.navToRefName = get(this, "$route.query.navToRefName", null);

    let newEmailToken = get(this, "$route.query.emailchanged", null);
    if (newEmailToken) {
      await UserService.updateEmail(newEmailToken);
    }
    await this.getUserData();
  }
};
</script>
