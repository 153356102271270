<template>
  <v-form
    class="user-data-form pa-3 pt-8 rounded"
    v-model="valid"
    :class="customClass"
    ref="form"
    @submit.prevent.stop="updateUserData"
  >
    <h3 v-if="isConnectingExistingCard" class="my-5">
      {{ $t("profile.askForCardModal.connectCardTitle") }}
    </h3>
    <h3 v-else-if="isAskingForCard" class="my-5">
      {{ $t("profile.askForCardModal.askCardTitle") }}
    </h3>
    <v-row>
      <!-- FIRST NAME -->
      <v-col cols="12" sm="6">
        <v-text-field
          :label="`${$t('profile.firstName')}`"
          v-model="userData.person.firstName"
          required
          :rules="[requiredRules()]"
          outlined
          flat
          disabled
          filled
          dense
          @change="validate"
        />
      </v-col>
      <!-- LAST NAME -->
      <v-col cols="12" sm="6">
        <v-text-field
          :label="`${$t('profile.lastName')}`"
          v-model="userData.person.lastName"
          required
          :rules="[requiredRules()]"
          outlined
          flat
          disabled
          filled
          dense
          @change="validate"
        />
      </v-col>

      <template v-if="!isConnectingExistingCard">
        <v-col cols="12" sm="3">
          <!-- PROVINCE -->
          <v-combobox
            :label="$t('register.user.province')"
            v-model="userData.billingAddress.province"
            :rules="[requiredRules()]"
            :items="getProvincesList(userData.person.province)"
            append-icon=""
            height="40"
            outlined
            flat
            filled
            dense
            return-object
            item-text="name"
            item-value="name"
            @change="changeProvince"
            autocomplete="chrome-off"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="6">
          <!-- CITY (Italy) -->
          <v-combobox
            :label="$t('register.user.city')"
            v-model="userData.billingAddress.city"
            :rules="[requiredRules()]"
            :items="getCitiesList(userData.billingAddress.city)"
            append-icon=""
            height="40"
            outlined
            flat
            filled
            dense
            item-text="name"
            item-value="name"
            @change="changeCity"
            autocomplete="chrome-off"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- POSTAL CODE -->
          <v-combobox
            :label="$t('register.user.postalCode')"
            v-model="userData.billingAddress.postalcode"
            :rules="[requiredRules()]"
            :items="getCapsList(userData.billingAddress.postalcode)"
            append-icon=""
            height="40"
            outlined
            flat
            filled
            dense
            item-text="name"
            item-value="name"
            autocomplete="chrome-off"
            @change="changeCap"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="6">
          <!-- ADDRESS1 -->
          <v-text-field
            :label="$t('register.user.address')"
            v-model="userData.billingAddress.address1"
            :rules="[requiredRules()]"
            height="40"
            outlined
            flat
            filled
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- ADDRESS NUMBER -->
          <v-text-field
            :label="$t('register.user.addressNumber')"
            v-model="userData.billingAddress.addressNumber"
            :rules="[requiredRules()]"
            outlined
            flat
            filled
            dense
            @change="validate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- INTERNO, apartmentNumber -->
          <v-text-field
            :label="$t('register.user.apartmentNumber')"
            v-model="userData.billingAddress.apartmentNumber"
            outlined
            flat
            filled
            dense
            @change="validate"
          ></v-text-field>
        </v-col>
      </template>

      <!-- HOME PHONE -->
      <v-col
        cols="12"
        sm="6"
        v-if="!isConnectingExistingCard && !isAskingForCard"
      >
        <v-text-field
          :label="`${$t('profile.contacts.homePhone')}`"
          v-model="userData.contact.homePhone"
          outlined
          flat
          filled
          dense
          @change="validate"
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        v-if="isConnectingExistingCard || isAskingForCard"
      >
        <v-text-field
          :label="`${$t('profile.contacts.mail')}`"
          v-model="userData.email"
          required
          outlined
          flat
          filled
          dense
          disabled
        ></v-text-field>
      </v-col>

      <!-- MOBILE PHONE -->
      <v-col cols="12" sm="6">
        <v-text-field
          :label="`${$t('profile.contacts.mobilePhone')}`"
          v-model="userData.phone"
          required
          :rules="mobilePhoneRules"
          outlined
          flat
          filled
          dense
          :disabled="isConnectingExistingCard || isAskingForCard"
          @change="validate"
        ></v-text-field>
      </v-col>

      <!-- BIRTHDATE -->
      <v-col cols="12" sm="6">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="birthDate"
              :label="`${$t('profile.birthDate')}`"
              :rules="[requiredRules()]"
              required
              outlined
              flat
              filled
              dense
              :disabled="birthDateEnabled"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon slot="append">$editcalendar</v-icon>
            </v-text-field>
          </template>
          <v-date-picker
            locale="it-IT"
            ref="picker"
            :active-picker.sync="activePicker"
            v-model="userData.person.birthDate"
            min="1910-01-01"
            :max="this.maxDate.format('YYYY-MM-DD')"
            first-day-of-week="1"
            type="date"
            no-title
            @input="onBirthdateInput"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        class="d-flex"
        v-if="!isAskingForCard && !isConnectingExistingCard"
      >
        <!-- GENDER -->
        <label v-html="`${$t('profile.gender')}`" class="mr-6 mt-2" />
        <v-radio-group
          dense
          v-model="userData.person.gender"
          mandatory
          :column="!$vuetify.breakpoint.xs"
          row
          :rules="[requiredRules()]"
          class="mt-1"
        >
          <v-radio value="M"
            ><template v-slot:label>
              <span>M</span>
            </template></v-radio
          >
          <v-radio value="F"
            ><template v-slot:label>
              <span>F</span>
            </template></v-radio
          ></v-radio-group
        >
      </v-col>

      <v-col cols="12" sm="6">
        <!-- punto vendita di riferimento -->
        <v-select
          ref="favouriteWh"
          v-model="userData.defaultStoreAddress.addressId"
          :items="warehouses"
          item-text="address.addressName"
          item-value="address.addressId"
          :label="`${$t('register.user.favouriteWh')}`"
          :placeholder="$t('register.user.favouriteWh')"
          filled
          dense
          outlined
          flat
        ></v-select>
      </v-col>

      <!-- FIDELITY CARD -->
      <v-col cols="12" sm="6" v-if="isConnectingExistingCard">
        <v-text-field
          :label="`${$t('profile.askForCardModal.fidelityCard')}`"
          v-model="userData.fidelityCard"
          required
          :rules="fidelityCardRules"
          outlined
          flat
          filled
          dense
          @change="validate"
        ></v-text-field>
      </v-col>
    </v-row>

    <br />

    <template v-if="!isAskingForCard && !isConnectingExistingCard">
      <div class="agreement-container  grey lighten-2 rounded pa-3">
        <h4 class="agreement-title mb-3">
          {{ $t("register.user.agreementTitle") }}
        </h4>

        <router-link
          class="privacy-link default--text"
          :to="$t('register.user.privacyLink')"
          >{{ $t("register.user.privacyText") }}</router-link
        >
        <span class="d-block agreement mt-2">
          {{ $t("register.user.agreement") }}
        </span>
        <span class="d-block profilation-title font-weight-bold mt-2">
          {{ $t("register.user.profilationTitle") }}
        </span>
        <span class="d-block profilation-descr">
          {{ $t("register.user.profilationDescr") }}
        </span>
        <v-radio-group
          dense
          :rules="[requiredRules()]"
          v-model="disclaimer1"
          row
        >
          <v-radio :value="'true'">
            <template v-slot:label>
              <span class="disclaimer-label disclaimer-radio-label">{{
                $t("common.yes")
              }}</span>
            </template>
          </v-radio>
          <v-radio :value="'false'">
            <template v-slot:label>
              <span class="disclaimer-label disclaimer-radio-label">{{
                $t("common.no")
              }}</span>
            </template>
          </v-radio>
        </v-radio-group>

        <span class="d-block marketing-title font-weight-bold mt-2">
          {{ $t("register.user.marketingTitle") }}
        </span>
        <span class="d-block marketing-descr">
          {{ $t("register.user.marketingDescr") }}
        </span>
        <v-radio-group
          :rules="[requiredRules()]"
          dense
          v-model="disclaimer2"
          row
        >
          <v-radio :value="'true'">
            <template v-slot:label>
              <span class="disclaimer-label disclaimer-radio-label">{{
                $t("common.yes")
              }}</span>
            </template>
          </v-radio>
          <v-radio :value="'false'">
            <template v-slot:label>
              <span class="disclaimer-label disclaimer-radio-label">{{
                $t("common.no")
              }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </template>

    <span
      v-if="isConnectingExistingCard"
      class="connect-card-info d-block my-5"
    >
      <v-icon class="mr-5">$info</v-icon
      >{{ $t("profile.askForCardModal.connectCardInfo") }}
    </span>
    <span v-else-if="isAskingForCard" class="is-ask-for-card-info d-block my-5">
      <v-icon class="mr-5">$info</v-icon
      >{{ $t("profile.askForCardModal.askCardInfo") }}
    </span>

    <span class="d-block mandatory-text ">{{
      $t("profile.mandatoryField")
    }}</span>

    <div
      class="become-prima-container my-6 d-flex flex-column  justify-start align-center flex-sm-row aling-items"
      v-if="!isAskingForCard && !isConnectingExistingCard"
    >
      <div class="d-flex">
        <img
          src="/img_layout/prima_cards/prima-card-verde.png"
          alt="prima card verde"
          title="prima card verde"
          class="prima-card-img mr-5"
        />
        <img
          src="/img_layout/prima_cards/prima-card-rossa.png"
          alt="prima card rossa"
          title="prima card rossa"
          class="prima-card-img mr-5"
        />
      </div>

      <span v-if="userData.fidelityCard" class="font-weight-bold">
        {{ $t("profile.fidelityCardNumber") }}&nbsp;{{ userData.fidelityCard }}
      </span>
      <template v-else>
        <v-row class="justify-start align-center">
          <v-col cols="12" sm="6" md="auto">
            <v-btn
              ref="wantPrima"
              outlined
              color="primary"
              class="mr-0 mr-sm-10 my-5 my-sm-0 w-100"
              @click="openAskForCardModal(false)"
            >
              {{ $t("profile.connectPrimaCardBtn") }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            :md="!hasRequestedFidelityCard ? 'auto' : '5'"
          >
            <v-btn
              v-if="!hasRequestedFidelityCard"
              outlined
              color="primary"
              class="mb-5 mb-sm-0 w-100"
              @click="openAskForCardModal(true)"
            >
              {{ $t("profile.requestPrimaCardBtn") }}
            </v-btn>

            <p v-else>
              {{ $t("register.user.primaCard65RequestSent") }}
            </p>
          </v-col>
        </v-row>
        <div class="d-flex flex-column"></div>
      </template>
    </div>

    <ResponseMessage class="mt-3" :response="responseMessage" />

    <div class="d-flex justify-end ">
      <v-btn
        v-if="!isAskingForCard && !isConnectingExistingCard"
        :loading="loading"
        type="submit"
        class="update-user-btn"
        color="primary"
        min-width="250"
        large
        depressed
      >
        {{ $t("profile.updateUserBtn") }}
      </v-btn>
      <v-btn
        v-else
        :loading="loading"
        type="submit"
        class="update-user-btn ask-for-card-btn"
        color="primary"
        min-width="250"
        large
        depressed
      >
        {{ $t("profile.askForCardModal.askForCardBtn") }}
      </v-btn>
    </div>
  </v-form>
</template>

<style lang="scss">
.user-data-form {
  .prima-card-img {
    max-width: 57px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .update-user-btn {
      width: 100%;
    }
  }
}
</style>

<script>
import ResponseMessage from "@/components/ResponseMessage.vue";
import UserData from "@/components/profile/form/UserData.vue";

import BaskoCustomService from "@/service/baskoCustomService";
import AddressService from "~/service/addressService";
import UserService from "~/service/userService";

import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { mapActions } from "vuex";
import { getPersonInfo, setPersonInfo } from "~/service/ebsn";

import {
  requiredValue,
  isCF,
  isEmail,
  isLength,
  isMobilePhone
} from "~/validator/validationRules";

export default {
  name: "UserDataForm",
  components: { ResponseMessage },
  props: {
    userData: {
      type: Object,
      required: true
    },
    customClass: { type: String, default: "" },
    isAskingForCard: { type: Boolean, default: false },
    isConnectingExistingCard: { type: Boolean, default: false },
    navToRefName: { type: String, required: false }
  },
  data() {
    return {
      userDataClone: null,
      menu: false,
      countries: [],
      requiredRules: requiredValue,
      birthDateEnabled: false,
      activePicker: null,
      fidelityCardRules: [
        requiredValue(),
        isLength(13, "La PrimaCard è lunga 13 numeri")
      ],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      dateRules: [requiredValue("Digitare la data di nascita")],
      mobilePhoneRules: [
        requiredValue("Digitare il numero di cellulare"),
        isMobilePhone()
      ],
      cfRules: [requiredValue(), isCF()],
      valid: false,
      country: {
        itemId: "1",
        iso: "IT",
        name: "Italia"
      },
      selectedCityId: null,
      citiesUnderCap: [],
      provinceList: [],
      cityList: [],
      capList: [],
      warehouses: [],
      disclaimer1: null,
      disclaimer2: null,
      loading: false,
      responseMessage: {}
    };
  },
  computed: {
    birthDataFields() {
      return this.isRegisterWithCard ? true : !this.showFiscalCode;
    },
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    },
    hasRequestedFidelityCard() {
      return (
        this.userData &&
        this.userData.person &&
        this.userData.person.personInfos.some(info => {
          return info.personInfoTypeId === "4" && info.value === "1";
        })
      );
    },
    maxDate() {
      let eighteenYearsAgo = this.$dayjs().subtract(18, "year");
      return eighteenYearsAgo;
    }

    /*  {
      return
    }*/
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),

    async openAskForCardModal(isAskingForCard) {
      let res = await this.$dialog.show(UserData, {
        waitForResult: true,
        userData: this.userDataClone,
        isAskingForCard: isAskingForCard,
        isConnectingExistingCard: !isAskingForCard,
        customClass: "pa-7",
        width: 700,
        fullscreen: this.$vuetify.breakpoint.xsOnly
      });
      if (res) {
        if (this.isOver65()) {
          setPersonInfo(this.userDataClone.person, "4", "1");
          this.hasRequestedFidelityCard = true;
        }

        this.$emit("refreshUser");
      }
    },
    isOver65() {
      let date = this.userData.person.birthDate;
      if (date) {
        let birthDatePlus65Years = this.$dayjs(date).add(65, "year");
        return birthDatePlus65Years.isBefore(this.$dayjs()) ? true : false;
      }
      return false;
    },
    async updateUserData() {
      try {
        if (this.isAskingForCard || this.isConnectingExistingCard) {
          let primaCardAction = this.isAskingForCard ? 3 : 1;
          primaCardAction =
            this.isOver65() && !this.isConnectingExistingCard
              ? 4
              : primaCardAction;

          let res = await BaskoCustomService.registrationPrimaUser(
            this.userData,
            primaCardAction
          );
          if (res.response && res.response.status == 0) {
            this.$emit("submit", true);
          } else {
            this.responseMessage = res.response;
          }
        } else {
          this.loading = true;
          if (this.$refs.form.validate()) {
            this.responseMessage = {};

            setPersonInfo(this.userData.person, 1, this.disclaimer1);
            setPersonInfo(this.userData.person, 2, this.disclaimer2);
            setPersonInfo(this.userData.person, 3, this.disclaimer2);

            let res = await UserService.updateUserDetail(this.userData);
            if (res) {
              this.birthDateEnabled = get(res, "person.birthDate")
                ? true
                : false;
              this.getCart();
              this.$emit("refreshUser");
            }
          } else {
            this.responseMessage = {
              error: this.$t("common.incorrectFormMessage")
            };
          }
        }
      } catch (response) {
        if (typeof response === "undefined") {
          this.responseMessage = {
            errors: [{ error: this.$t("errors.errorCompletingRegistration") }]
          };
        } else {
          this.responseMessage = response;
        }
      } finally {
        this.loading = false;
      }
    },
    onBirthdateInput() {
      this.menu = false;
      this.validate();
    },
    // address
    changeProvince(province) {
      this.userData.billingAddress.province = province.name;
      this.userData.billingAddress.city = null;
      this.userData.billingAddress.postalcode = null;
      this.userData.billingAddress.address1 = null;
      this.cityList = [];
      this.capList = [];
      this.selectedCityId = null;
      this.getCityList(province.itemId);
    },
    changeCity(city) {
      this.userData.billingAddress.city = city.name;
      this.userData.billingAddress.postalcode = null;
      this.userData.billingAddress.address1 = null;
      this.capList = [];
      this.selectedCityId = city.itemId;
      this.getCapList(city.itemId);
    },
    changeCap(cap) {
      this.userData.billingAddress.postalcode = cap.name;
      this.userData.billingAddress.address1 = null;
    },
    async getCityList(provinceId) {
      this.cityList = await BaskoCustomService.cityList(provinceId);
    },
    async getCapList(capId) {
      this.capList = await BaskoCustomService.postcodeList(capId);
    },
    getCitiesList(text) {
      let _this = this;
      var filteredCities = [];
      let insertedText = text?.name || text;
      if (insertedText) {
        var formattedText = insertedText.toLowerCase();
        for (var i = 0; i < _this.cityList.length; i++) {
          var formattedCityName = _this.cityList[i].name.toLowerCase();
          if (
            (formattedText.length == 1 &&
              formattedCityName[0] == formattedText) ||
            (formattedText.length > 1 &&
              formattedCityName.includes(formattedText))
          ) {
            filteredCities.push(_this.cityList[i]);
          }
        }
      } else {
        filteredCities = _this.cityList;
      }
      return filteredCities;
    },
    getCapsList(text) {
      let _this = this;
      var filteredCaps = [];
      let insertedText = text?.name || text;
      if (insertedText) {
        var formattedText = insertedText.toLowerCase();
        for (var i = 0; i < _this.capList.length; i++) {
          var formattedCapName = _this.capList[i].name.toLowerCase();
          if (
            (formattedText.length == 1 &&
              formattedCapName[0] == formattedText) ||
            (formattedText.length > 1 &&
              formattedCapName.includes(formattedText))
          ) {
            filteredCaps.push(_this.capList[i]);
          }
        }
      } else {
        filteredCaps = _this.capList;
      }
      return filteredCaps;
    },
    getProvincesList(text) {
      let _this = this;
      var filteredProvinces = [];
      let insertedText = text?.name || text;
      if (insertedText) {
        var formattedText = insertedText.toLowerCase();
        for (var i = 0; i < _this.provinceList.length; i++) {
          var formattedProvinceName = _this.provinceList[i].name.toLowerCase();
          if (
            (formattedText.length == 1 &&
              formattedProvinceName[0] == formattedText) ||
            (formattedText.length > 1 &&
              formattedProvinceName.includes(formattedText))
          ) {
            filteredProvinces.push(_this.provinceList[i]);
          }
        }
      } else {
        filteredProvinces = _this.provinceList;
      }
      return filteredProvinces;
    },
    async getProvinces(homeDeliveryOnly) {
      let _this = this;
      if (_this.provinceList.length < 1) {
        let res = await BaskoCustomService.provinceList(
          this.country.itemId,
          homeDeliveryOnly
        );
        // .then(function(results) {
        //   _this.provinceList = results;
        // });

        _this.provinceList = res;
      }
    },
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
        }
      }, 200);
    },
    initDisclaimer() {
      let personInfo1 = getPersonInfo(this.userData.person, 1);
      if (personInfo1) {
        this.disclaimer1 = personInfo1;
      } else {
        setPersonInfo(this.userData.person, 1, undefined);
      }
      let personInfo2 = getPersonInfo(this.userData.person, 2);
      if (personInfo2) {
        this.disclaimer2 = personInfo2;
        setPersonInfo(this.userData.person, 3, personInfo2);
      } else {
        setPersonInfo(this.userData.person, 2, undefined);
        setPersonInfo(this.userData.person, 3, undefined);
      }
    },
    async getWarehouses() {
      let res = await AddressService.findWarehouse();
      if (res && res.warehouses) {
        this.warehouses = res.warehouses;
      }
    },
    async fetchAddressData() {
      try {
        await this.getProvinces(false);
        let provinceData = this.provinceList.find(
          province => province.name == this.userData.billingAddress.province
        );

        await this.getCityList(provinceData.itemId);

        let cityData = this.cityList.find(
          city => city.name == this.userData.billingAddress.city
        );
        this.getCapList(cityData.itemId);
      } catch (err) {
        console.log(err);
      }
    }
  },
  async mounted() {
    this.userDataClone = cloneDeep(this.userData);
    this.userDataClone.defaultStoreAddress = {};
    this.birthDateEnabled = get(this.userData, "person.birthDate")
      ? true
      : false;
    this.initDisclaimer();

    this.getWarehouses();

    this.fetchAddressData();

    let _this = this;
    if (this.navToRefName) {
      setTimeout(
        () => _this.$vuetify.goTo(_this.$refs[this.navToRefName]),
        500
      );
    }
  },
  watch: {
    // userData(val) {
    //   if (val.person.personInfos) {
    //     val.person.personInfos.forEach(element => {
    //       if (element.personInfoTypeId == 1) {
    //         this.disclaimer1 = element.value;
    //       }
    //       if (element.personInfoTypeId == 2) {
    //         this.disclaimer2 = element.value;
    //       }
    //     });
    //   }
    // },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  }
};
</script>
